import { t } from 'i18next'
import { FloatContactUs } from "../../../../Component/FloatContactUs"
import { Title } from "../../../../Component/Title"
import { useHoverGlow } from "../../../../Effects/useHoverGlow"
import icon from './../../../../img/title/icon2.svg'

export const GetConnected = () => {
    useHoverGlow(".rightSection")
    return (
        <div id="getConnected" className="section">
            <Title title={"GET CONNECTED"} icon={icon} />

            <div className="imageContainer" style={{
                backgroundImage: `url(/images/getToKnowUs/family_cell_gtku.png)`
            }}>
                {/* <img className="mask" src={example} /> */}
            </div>

            <div className="contactUsContainer">
                <span>Got any question?</span>
                <span>Don't hesitate to</span>
                <FloatContactUs />
            </div>
            
            <div className="rightSection">
                <h3>FAMILY CELL</h3>
                <p>{t('home.familyCell')}</p>

                {/* <Link to="/lets-grow#familyCellAnchor" className="link">
                    // discover more
                </Link> */}
            </div>
        </div>
    )
}