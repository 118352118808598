import { FloatContactUs } from "../../../Component/FloatContactUs";
import { TitleFlex } from "../../../Component/TitleFlex";
import { Icon } from "../../../Component/Vector/Title/Icon";
import { IFamilyCellData } from "../Interface/FamilyCell";

export const FamilyCell = (props: {data?: IFamilyCellData}) => {
    const { quote, article1, article2, article3, tagline, callToAction, image } = props.data ?? {
        quote: "-",
        article1: "-",
        article2: "-",
        article3: "-",
        tagline: "-",
        callToAction: "-",
        image: ""
    };

    return (
        <div id="familyCell" className="fullWidthContainer darkBackground letsGrowSection">
            <div className="anchorPoint" id="familyCellAnchor" />
            <div className="fullWidthContainer">
                <TitleFlex title="Family Cell" icon={<Icon />} />
            </div>
            <div className="padContainer">
                <div className="flexColumn">
                    <div
                        className="imageContainer"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    />
                    <div className="contentContainer">
                        <div className="quote">{quote}</div>
                        <div className="flexRow article">
                            <div
                                className="flexColumn"
                                dangerouslySetInnerHTML={{ __html: article1 }}
                            />
                            <div
                                className="flexColumn"
                                dangerouslySetInnerHTML={{ __html: article2 }}
                            />
                            <div
                                className="flexColumn"
                                dangerouslySetInnerHTML={{ __html: article3 }}
                            />
                        </div>
                    </div>
                    <div className="flexRow callAction">
                        <div className="flexColumn callActionContainer">
                            <div className="tagline">{tagline}</div>
                            <p>{callToAction}</p>
                        </div>
                        <FloatContactUs />
                    </div>
                </div>
            </div>
        </div>
    );
}