import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useUpdateLinkOnScroll = (elementClass: string, manual?: boolean, rootTop?: number, rootBottom?: number) => {
    const navigate = useNavigate()
    const location = useLocation()
    
    const [observer, setObserver] = useState<IntersectionObserver | undefined>(undefined)
    const [stickyId, setStickyId] = useState<string | undefined>(undefined)
    let stickyElements: any = undefined
    let resizeDebounceTimer: any = undefined

    useEffect(() => {
        if(!manual) init()
        
        return () => {
            window.removeEventListener("resize", onResize)
            destroyObserver()
        }
    }, [])

    const init = () => {
        stickyElements = document.querySelectorAll(elementClass)
        window.addEventListener("resize", onResize)
        setupObserver()
    }

    const onResize = () => {
        clearTimeout(resizeDebounceTimer);
        resizeDebounceTimer = setTimeout(() => {
            destroyObserver()
            setupObserver()
          }, 200);
    }

    useEffect(() => {
        navigate({
            pathname: location.pathname,
            search: location.search,
            hash: stickyId ?? undefined
        }, {
            replace: true
        })
    }, [stickyId])

    const onIntersect = (entries: any) => {
        // console.log(
        //     JSON.stringify(entries.map((entry: any) => ({
        //         target: entry.target.querySelector(".anchorPoint")?.getAttribute("id"),
        //         visible: entry.isVisible
        //     })), undefined, 2)
        // )
        if(entries[0].isIntersecting) {
            setStickyId(entries[0].target.querySelector(".anchorPoint")?.getAttribute("id"))
        }
    }

    const setupObserver = () => {
        const topRootMargin = rootTop ?? 90
        const bottomMinusElement = rootBottom ?? 190
        const bottomRootMargin = window.innerHeight - bottomMinusElement
        
        const observer = new IntersectionObserver(onIntersect, { 
            threshold: 0,
            rootMargin: `-${topRootMargin}px 0px -${bottomRootMargin}px 0px`
        })

        stickyElements.forEach((element: any) => {
            observer.observe(element)
        })

        setObserver(observer)
    }

    const destroyObserver = () => {
        setObserver(undefined)
    }

    return { init, reinit: onResize, deinit: destroyObserver, observer }
}