import { useEffect, useRef } from "react";

export const ContactUsBackground = (props: { maxWidth: number }) => {
    const { maxWidth } = props;

    const svgContent = useRef<any>(null)
    let width = window.innerWidth > maxWidth ? maxWidth : window.innerWidth;
    const height = 72;
    const leftArea = height;

    const handleResize = () => {
        width = window.innerWidth > maxWidth ? maxWidth : window.innerWidth;
        const svg = svgContent.current;
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`)

        const path = svg.querySelector('path');
        path.setAttribute('d', `M${leftArea} 0 H${width} V${height} H0 A${height} ${height} 0 0 1 ${leftArea} 0`)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize()
    }, [])

    return (
        <svg ref={svgContent} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
            <path d={`M${leftArea} 0 H${width} V${height} H0 A${height} ${height} 0 0 1 ${leftArea} 0`} fill="none" stroke="black" strokeWidth="2" />
        </svg>
    )
}