import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { API } from "../../Component/API"
import { FloatContactUs } from "../../Component/FloatContactUs"
import { Socmed } from "../../Component/Socmed"
import { useGetData } from "../../Component/getData"
import { useUpdateLinkOnScroll } from "../../Component/useUpdateLinkOnScroll"
import { Menu } from "./Component/Menu"
import { ILetsGrow } from "./Interface/LetsGrow"
import { FamilyCell } from "./Section/FamilyCell"
import { Grow } from "./Section/Grow"
import { MembershipClass } from "./Section/MembershipClass"
import { NewLife } from "./Section/NewLife"
import { WaterBaptism } from "./Section/WaterBaptism"

export const LetsGrow = () => {
    const { data, loading } = useGetData<ILetsGrow>(API.letsGrow)
    const { init } = useUpdateLinkOnScroll(".letsGrowSection", true)
    
    const location = useLocation()

    useEffect(() => {
        if(location.hash && loading === false){
            setTimeout(() => {
                window.location.replace(location.hash)
                init()
            }, 100)
            return
        }

        if(!location.hash && loading === false){
            init()
        }
    }, [loading])

    return (
        <div id="letsGrow">
            <Menu />
            <div className="socmedContainer">
                <FloatContactUs />
                <Socmed />
            </div>
            <NewLife data={data?.newLife} />
            <WaterBaptism data={data?.waterBaptism} />
            <Grow data={data?.grow} />
            <MembershipClass data={data?.membershipClass} />
            <FamilyCell data={data?.familyCell} />
            <div className="emptySpace" style={{
                height: "300px"
            }} />
        </div>
    )
}