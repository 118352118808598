import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const hash = {
    ourVision: '#ourVisionAnchor',
    ourValues: '#ourValuesAnchor',
    ourLeaders: '#ourLeadersAnchor',
    fivePillars: '#fivePillarsAnchor',
    visitUs: '#visitUsAnchor'
}

export const Menu = () => {
    const location = useLocation()

    const [selectedHash, setSelectedHash] = useState(location.hash !== '' ? location.hash : hash.ourVision)

    useEffect(() => {
        setSelectedHash(location.hash !== '' ? location.hash : hash.ourVision)
    }, [location.hash])
    
    return (
        <div id="getToKnowUsMenu">
            <ul className="menu">
                {/* <li><a href={hash.ourVision}
                    className={selectedHash === hash.ourVision ? 'selected' : ''}
                >Our Vision</a></li>
                
                <li><a href={hash.ourValues}
                    className={selectedHash === hash.ourValues ? 'selected' : ''}
                >Our Values</a></li> */}

                <li><a href={hash.ourLeaders}
                    className={selectedHash === hash.ourLeaders ? 'selected' : ''}
                >Our Pastors</a></li>
                
                {/* <li><a href={hash.fivePillars}
                    className={selectedHash === hash.fivePillars ? 'selected' : ''}
                >Five Pillars</a></li> */}

                <li><a href={hash.visitUs}
                    className={selectedHash === hash.visitUs ? 'selected' : ''}
                >Visit Us</a></li>
            </ul>
        </div>
    )
}