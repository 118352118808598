import { TitleFlex } from "../../../Component/TitleFlex"
import { Icon } from "../../../Component/Vector/Title/Icon"
import { PlatformDataNoButton } from "../Component/PlatformData"
import { IGrowCardData } from "../Interface/Grow"

export const Grow = (props: {data?: IGrowCardData[]}) => {
    const { data } = props

    return (
        <div id="grow" className="fullWidthContainer darkBackground letsGrowSection">
            <div className="anchorPoint" id="growAnchor" />
            <div className="fullWidthContainer">
                <TitleFlex title="Grow" icon={<Icon />} />
            </div>
            <div className="padContainer">
                <GrowCards data={data} />
            </div>
        </div>
    )
}

const GrowCards = (props: { data?: IGrowCardData[] }) => {
    const { data } = props

    if (!data) return <></>

    return (
        <div className="growCards flexColumn">
            {data.map((card, index) => (
                <GrowCard
                    key={index}
                    title={card.title}
                    image={card.image}
                    mask={card.mask}
                    description={card.description}
                    className={card.className}
                />
            ))}
        </div>
    )
}

const GrowCard = (props: {title: string, image: string, mask: string, description: string, className: string}) => {
    const { title, image, mask, description, className } = props
    return (
        <div className={`growCard fullWidthContainer flexColumn ${className}`}>
            <div className="leftBorder" />
            <div className="image" style={{
                WebkitMaskImage: `url(${mask})`,
                maskImage: `url(${mask})`,
                backgroundImage: `url(${image})`
            }} />
            <div className="content">
                <div className="title">
                    {title}
                </div>
                <p>
                    {description}
                </p>
            </div>
            {/* <PlatformDataButtonOnly className="paddingLeft" /> */}
            <PlatformDataNoButton className="light leftAligned" />
        </div>
    )
}