import { API } from "../../Component/API";
import { FloatContactUs } from "../../Component/FloatContactUs";
import { Socmed } from "../../Component/Socmed";
import { useGetData } from "../../Component/getData";

import { useUpdateLinkOnScroll } from "../../Component/useUpdateLinkOnScroll";
import { Menu } from "./Component/Menu";
import { IGetToKnowUs } from "./Interface";
import { OurLeaders } from "./Section/OurLeaders";
import { VisitUs } from "./Section/VisitUs";

export const GetToKnowUs = () => {
    useUpdateLinkOnScroll(".sticky")
    const { data } = useGetData<IGetToKnowUs>(API.getToKnowUs)

    return (
        <div id="getToKnowUs">
            <Menu />
            <div className="socmedContainer">
                <FloatContactUs />
                <Socmed />
            </div>
            
            {/* <OurVision />
            <OurValues /> */}
            <OurLeaders
                leaders={data?.leaders}
                leaders2={data?.leaders2}
            />
            {/* <FivePilars /> */}
            <VisitUs datas={data?.visit_us} />
            <div style={{
                height: "300px"
            }} />
        </div>
    )
}