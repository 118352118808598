import queryString from "query-string"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { API, BaseImagePath } from "../../Component/API"
import { OpenContactUs } from "../../Component/FloatContactUs"
import { FloatEventNotify } from "../../Component/FloatEventNotify"
import { TopMenuCategories } from "../../Component/TopMenuCategories"
import { useGetData } from "../../Component/getData"
import { PlatformDataNoButton } from "../LetsGrow/Component/PlatformData"
import { IEventCard } from "./Inteface"

export const Events = () => {
    const { data } = useGetData<IEventCard[]>(API.events)
    const [events, setEvents] = useState<IEventCard[]>([])

    const location = useLocation()
    const navigate = useNavigate()
    const search = queryString.parse(location.search)

    const categories = [
        {
            label: "TODAY",
            url: "today"
        },
        {
            label: "THIS WEEK",
            url: "this-week"
        },
        {
            label: "UPCOMING EVENTS",
            url: "upcoming-events"
        }
    ]

    useEffect(() => {
        if (search.event == undefined) {
            navigate("/events?event=today", { replace: true })
        }
    }, [search.event])

    useEffect(() => {
        updateEvents()
    }, [data, search.event])

    const updateEvents = () => {
        if (!data) return

        const todayDate = new Date()
        const todayDay = todayDate.getDay();

        let firstDayInThisWeek = new Date()
        firstDayInThisWeek.setDate(todayDate.getDate() - todayDay + 1);
        let lastDayInThisWeek = new Date();
        lastDayInThisWeek.setDate(todayDate.getDate() + (7 - todayDay));

        setEvents(data.filter((event) => {
            const eventDate = new Date(event.date)
            console.log(eventDate.getDate(), todayDate)

            if (search.event == "today") {
                return eventDate.getDate() === todayDate.getDate() &&
                    eventDate.getMonth() === todayDate.getMonth()
            }
            else if (search.event == "this-week") {
                return eventDate >= firstDayInThisWeek &&
                    eventDate <= lastDayInThisWeek
            }
            else {
                return eventDate > lastDayInThisWeek
            }
        }))
    }

    return (
        <div id="events">
            <TopMenuCategories baseUrl="events" categories={categories} parameterName={"event"} />
            <div className="fullWidthContainer">
                <div className="eventsPaddingContainer">
                    <EventCards data={events} />
                </div>
            </div>
            <div style={{
                height: "300px"
            }} />
        </div>
    )
}

const EventCards = (props: { data?: IEventCard[] }) => {
    const { data } = props
    const { t } = useTranslation();

    if (!data || data.length === 0) return (
        <div id="emptyEvent">
        { t('event.noEvent') }
        </div>
    )

    return (
        <div id="eventCards" className="flexColumn">
            {data.map((event, index) => (
                <EventCard key={index} data={event} />
            ))}
        </div>
    )
}

const EventCard = (props: { data: IEventCard }) => {
    const { id, title, date, image_url, tagline, description, join_link, join_link_enable, info } = props.data;

    const eventDate = new Date(date);
    const day = eventDate.getDate();
    const month = eventDate.toLocaleString('default', { month: 'short' });

    return (
        <div className="eventCard">
            <div className="flexColumn">
                <div className="flexRow heading">
                    <div className="flexColumn titlePlatform">
                        <div className="headerContainer style1">
                            <div className="ornament">
                                <div className="left">
                                    <div className="lineContainer">
                                        <div className="hLine1" />
                                        <div className="hLine2" />
                                    </div>
                                </div>
                                <div className="center">
                                    <div className="lineContainer">
                                        <div className="hLine1 half" />
                                        <div className="hLine2 half" />
                                    </div>
                                </div>
                                <div className="right" >
                                </div>
                            </div>
                            {/* <div className="lineContainer">
                                <div className="hLine1" />
                                <div className="hLine2" />
                            </div> */}
                            <h2>{title}</h2>
                        </div>
                        <PlatformDataNoButton className="bordered shadowed" info={info} />
                    </div>
                    <div className="topLine" />
                    <div className="flexColumn date">
                        <div className="day">{day}</div>
                        <div className="month">{month}</div>
                    </div>
                </div>
                <div className="imageContainer">
                    <div className="image" style={{
                        backgroundImage: `url(${BaseImagePath}${image_url})`
                    }} />
                </div>
                <div className="contentContainer flexRow">
                    <div className="flexColumn content">
                        <div className="tagline">{tagline}</div>
                        <p>{description}</p>
                    </div>
                    <div className="flexRow eventButtons">
                        <button className="normalButton contactUs" onClick={() => {
                            OpenContactUs(`Event: ${title}`)
                        }}>Contact Us</button>
                        <FloatEventNotify title={title} event_id={id} />
                        { join_link_enable && join_link && 
                        <button 
                            className={`normalButton live ${ join_link_enable && join_link ? "isLive" : ""}`}
                            {...(join_link_enable && join_link ? {
                                onClick: () => window.open(join_link, "_blank")
                            } : {})}                            
                        >Join</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
