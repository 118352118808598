import queryString from "query-string"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const useTitleUpdater = () => {
    const location = useLocation()
  
    useEffect(() => {
      const path = "/" + location.pathname.split("/")[1] 
      
      document.title = TitleSiteMap[path]
      if(location.search) {
        const searches = queryString.parse(location.search)
        Object.keys(searches).forEach(key => {
          document.title += ` - ${capitalizeWords(searches[key] as string)}`
        })
      }
      if(location.hash) {
        document.title += ` - ${capitalizeWords(location.hash.replace("#", "").replace("Anchor", ""))}`
      }
    }, [location])
  }
  
  const prefixSiteTitle = "GBI Gilgal - "
  
  const SitePath = {
    home: "/",
    getToKnowUs: "/get-to-know-us",
    ministries: "/ministries",
    letsGrow: "/lets-grow",
    events: "/events",
    media: "/media",
    give: "/give",
    unsubscribe: "/unsubscribe"
  }
  
  const TitleSiteMap = {
    [SitePath.home]: `${prefixSiteTitle}Home`,
    [SitePath.getToKnowUs]: `${prefixSiteTitle}Get To Know Us`,
    [SitePath.ministries]: `${prefixSiteTitle}Ministries`,
    [SitePath.letsGrow]: `${prefixSiteTitle}Let's Grow`,
    [SitePath.events]: `${prefixSiteTitle}Events`,
    [SitePath.media]: `${prefixSiteTitle}Media`,
    [SitePath.give]: `${prefixSiteTitle}Give`,
    [SitePath.unsubscribe]: `${prefixSiteTitle}Unsubscribe`
  }
  
  const capitalizeWords = (input: string) => {
    input = camelCaseToNormalText(input)
    input = input.toLowerCase();
    return input
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  
  const camelCaseToNormalText = (input: string) => {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }