import specialButtonIcon from './../img/card/specialButton/icon.svg';

interface ISpecialButtonService {
    join_link: string;
    join_link_enable: boolean;
}

export const SpecialButton = (props: ISpecialButtonService) => {
    const { join_link, join_link_enable } = props
    return (
        <div className="specialButton">
            <img src={specialButtonIcon} />
            <div className="line" />
            <button
                className={`normalButton live ${ join_link_enable && join_link ? "isLive" : ""}`}
                {...(join_link_enable && join_link ? {
                    onClick: () => window.open(join_link, "_blank")
                } : {})}
            >
                Join!
            </button>
        </div>
    )
}