import { API } from "../../Component/API";
import { useGetData } from "../../Component/getData";
import { IHome } from "./HomeController";
import { GetConnected } from "./Section/GetConnected/GetConnected";
import { Landing } from "./Section/Landing/Landing";
import { OurServices } from "./Section/OurServices/OurService";
import { SignIn } from "./Section/SignIn/SignIn";
import { WeAreFamily } from "./Section/WeAreFamily/WeAreFamily";

export const Home = () => {
  const { data } = useGetData<IHome>(API.home);

  return (
    <>
      <Landing slide={data?.slides} />
      <OurServices data={data?.services} />
      <GetConnected />
      <WeAreFamily slide={data?.leaders} />
      <SignIn />
    </>
  );
}