
import CryptoJS from "crypto-js"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { API } from "../../Component/API"

export const Unsubscribe = () => {
    const { code } = useParams()
    const navigate = useNavigate()

    const [unsubscribeData, setUnsubscribeData] = useState<{
        subscriber_type_id: number,
        subscriber_type_name: string,
        subscriber_id: number,
        subscriber_name: string
    } | undefined>(undefined)

    const [isUnsubscribed, setIsUnsubscribed] = useState(false)
    

    useEffect(() => {
        console.log(code)
        if(!code){ navigate("/"); return }
        console.log(decodeURIComponent(code))
        var decrypted = JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(code), "123", {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
        console.log(decrypted)
        setUnsubscribeData(JSON.parse(decrypted as any))
        
    }, [code])

    if(unsubscribeData === undefined) return <></>

    const doUnsubscribe = () => {
        if(!code){ toast.error("Invalid subscription", {
            autoClose: 500,
            hideProgressBar: true,
            theme: "dark"
        }); return }
        
        setTimeout(async () => {
            const response = await fetch(`${API.unsubscribe}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {code: decodeURIComponent(code)}
                )
            })

            if (response.status === 200) {
                toast.success("Unsubscribe success", {
                    autoClose: 500,
                    hideProgressBar: true,
                    theme: "dark"
                })
            }
            else {
                toast.error("Failed to unsubscribe", {
                    autoClose: 500,
                    hideProgressBar: true,
                    theme: "dark"
                })
            }
            setIsUnsubscribed(true)
        }, 0)
    }

    return ( 
        <div id="unsubscribe">
            <div className="unsubscriberContainer">
                <h1>Unsubscribe</h1>

                { !isUnsubscribed ?
                <>
                    { t('subscribe.unsubscribeConfirmation')
                        .replace('{ministries}', unsubscribeData.subscriber_type_name)
                        .replace('{name}', unsubscribeData.subscriber_name) 
                    }
                    <br />
                    <button className="normalButton join" onClick={() => doUnsubscribe()} >
                        Unsubscribe!
                    </button>
                </>
                : 
                <>
                    Already unsubscribed.
                </>
                }
            </div>
        </div>
    )
    
}

var CryptoJSAesJson = {
    stringify: function (cipherParams:any) {
        var j: any = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr: any) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams;
    }
}