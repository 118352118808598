import { t } from "i18next"
import { BaseImagePath } from "../../../Component/API"
import { LineHeightResponsive } from "../../../Component/LineHeightResponsive"
import { Title } from "../../../Component/Title2"
import { Icon } from "../../../Component/Vector/Title/Icon"
import { ILeader } from "../../Home/HomeController"

export const OurLeaders = (props: {leaders?: ILeader[], leaders2?: ILeader[]}) => {
    const { leaders, leaders2 } = props
    return (
        <div className="sticky ourLeaders" id="ourLeaders">
            <div className="anchorPoint" id="ourLeadersAnchor" />
            <Title title={"OUR PASTORS"} icon={<Icon />} />
            <LineHeightResponsive containerClassName="lineL" parentClassName="ourLeaders" />
            <LineHeightResponsive containerClassName="lineR" parentClassName="ourLeaders" />

            <div className="pad">
                <div className="section header">
                    <h2>PASTORS</h2>
                    <pre>
                        {t('getToKnowUs.pastor')}
                    </pre>
                </div>
            </div>
            
            <LeaderCards datas={leaders ?? []} />
            <LeaderCards style="style2" datas={leaders2 ?? []} />
        </div>
    )
}

const LeaderCards = (props: {style?: string, datas: ILeader[]}) => {
    const { style, datas } = props
    
    return (
        <div className="leaderCardsContainer">
            <div className={"leaderCards " + (style ? style : "")}>
                { datas.map((data, index) => {
                    return (
                        <LeaderCard 
                            key={index}
                            name={data.name}
                            title={data.title}
                            image_url={data.image_url}
                            short_description={data.short_description}
                            long_description={data.long_description}
                            link_text={data.link_text}
                        />
                    )
                })
                }
            </div>
            <div className="shadowOutline last" />
        </div>
    )
}

const LeaderCard = (props: ILeader) => {
    const { name, title, image_url, short_description, long_description, link_text } = props

    return (
        <div className="leaderCardContainer">
            <div className="shadowOutline" />
            <div className="pad">
                <div className="leaderCardContent">
                    <h2>{name}</h2>
                    <div className="title">{title}</div>
                    <div className="leaderCard">
                        <div className="outline" />
                        <div className="data">
                            <div className="image" style={{
                                backgroundImage: `url(${BaseImagePath}${image_url})`
                            }} />
                            <div className="text">
                                <p>
                                    <span className="firstCharacter">{long_description.charAt(0) }</span>
                                    { long_description.slice(1) }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}