import { useEffect } from "react"
import { LineHeightResponsive } from "../../../Component/LineHeightResponsive"
import { Pagination } from "../../../Component/Pagination2"
import { Title } from "../../../Component/Title2"
import { Icon } from "../../../Component/Vector/Title/Icon"
import { useSlide } from "../../../Component/useSlide"
import { IVisitUsData } from "../Interface"

import iconEmail from './../../../img/page/getToKnowUs/iconEmail.svg'
import iconLocation from './../../../img/page/getToKnowUs/iconLocation.svg'
import iconPhone from './../../../img/page/getToKnowUs/iconPhone.svg'

import { BaseImagePath } from "../../../Component/API"
import fb from './../../../img/socialMedia/fb.svg'
import ig from './../../../img/socialMedia/ig.svg'
import tk from './../../../img/socialMedia/tk.svg'
import yt from './../../../img/socialMedia/yt.svg'

export const VisitUs = (props: {datas?: IVisitUsData[]}) => {
    const { slide, onSlideUpdate, previousSlide, nextSlide } = useSlide(props.datas ?? [], "visitUsCard")

    useEffect(() => {
        onSlideUpdate()
    }, [props.datas])

    const pagination = <Pagination id="mapPagination" prevSlide={previousSlide} nextSlide={nextSlide} />

    return (
        <div className="sticky visitUs" id="visitUs">
            <div className="anchorPoint" id="visitUsAnchor" />
            <Title title={"VISIT US"} icon={<Icon />} />
            <LineHeightResponsive containerClassName="lineL" parentClassName="visitUs" />
            
            <VisitUsCards datas={slide} pagination={pagination} />
        </div>
    )
}

const VisitUsCards = (props: {datas?: IVisitUsData[], pagination: JSX.Element}) => {
    const { datas, pagination } = props

    return (
        <div className="visitUsCards">
            { datas && datas.map((data, index) => {
                return (
                    <VisitUsCard
                        key={index}
                        data={data}
                        pagination={pagination}
                    />
                )
            })
            }
        </div>
    )
}

const VisitUsCard = (props: {data: IVisitUsData, pagination: JSX.Element}) => {
    const { data, pagination } = props
    
    return (
        <div className="visitUsCard">
            <div className="visitUsContainer">
                <div className="mapContainer">
                    <div className="overlay" />
                    <div className="mapImage clickable" style={{
                        backgroundImage: `url(${BaseImagePath}${data.place.image_url})`
                    }} onClick={() => {
                        data.place.map_url && window.open(data.place.map_url, "_blank")
                    }} />
                </div>
                { pagination }
            </div>
            <div className="visitUsContainer">
                <div className="infoContainer">
                    <div className="iRow flex100Mobile flexGap" style={{flex:1}}>
                        <div className="iCol flex100Mobile leftBorder noLeftBorderMidSize iContentContainer">
                            <div className="iCol clickable" onClick={() => {
                                data.place.map_url && window.open(data.place.map_url, "_blank")
                            }}>
                                <div className="iRow flexNoWrap">
                                    <div className="icon">
                                        <img src={iconLocation} />
                                    </div>
                                    <div className="iCol">
                                        <h4>{data.place.name}</h4>
                                        <p dangerouslySetInnerHTML={
                                            {__html: data.place.location}
                                        }/>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="iCol">
                                <div className="iRow flexNoWrap">
                                    <div className="icon">
                                        <img src={iconShare} />
                                    </div>
                                    <h4>Share To</h4>
                                </div>
                            </div> */}
                        </div>

                    </div>

                    <div className="iRow flex100Mobile flexGap">
                        <div className="iCol leftBorder iContentContainer">
                            <a className="iRow flexNoWrap socmed" href={data.social_media.email.url}>
                                <div className="icon">
                                    <img src={iconEmail} />
                                </div>
                                <p>{data.social_media.email.label}</p>
                            </a>
                            <a className="iRow flexNoWrap socmed" href={data.social_media.ig.url}>
                                <div className="icon">
                                    <img src={ig} />
                                </div>
                                <p>{data.social_media.ig.label}</p>
                            </a>
                            <a className="iRow flexNoWrap socmed" href={data.social_media.tiktok.url}>
                                <div className="icon">
                                    <img src={tk} />
                                </div>
                                <p>{data.social_media.tiktok.label}</p>
                            </a>
                            <a className="iRow flexNoWrap socmed" href={data.social_media.fb.url}>
                                <div className="icon">
                                    <img src={fb} />
                                </div>
                                <p>{data.social_media.fb.label}</p>
                            </a>
                            <a className="iRow flexNoWrap socmed" href={data.social_media.youtube.url}>
                                <div className="icon">
                                    <img src={yt} />
                                </div>
                                <p>{data.social_media.youtube.label}</p>
                            </a>
                        </div>
                        
                        <div className="iCol leftBorder iContentContainer">
                            { data.contact && data.contact.map((contact, index) => {
                                return (
                                    <a className="iRow flexNoWrap socmed" key={index} href={contact.url}>
                                        <div className="icon">
                                            <img src={iconPhone} />
                                        </div>
                                        <p>{contact.label}</p>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}