import { useEffect } from "react";

interface ISlide<T> {
    slide: T[]
    nextSlide: () => void
    previousSlide: () => void
    onSlideUpdate: () => void
    onSlideDestroy: () => void
}

export const useSlide: <T>(slide: T[], elementsName: string) => ISlide<T> = (slide, elementsName) => {
    const slideTime = 5000;
    let slidesElement: HTMLCollectionOf<Element>;
    let currentIndex = 0;
    let slideTimer: NodeJS.Timer;

    const showSlide = (index: number) => {
        for (let i = 0; i < slidesElement.length; i++) {
            slidesElement[i].classList.remove('show');
        }
        slidesElement[index].classList.add('show')
    }

    const nextSlide = () => {
        if (slidesElement.length === 0) return;
        
        currentIndex++;
        if (currentIndex >= slidesElement.length) {
            currentIndex = 0;
        }
        showSlide(currentIndex);
    }

    const previousSlide = () => {
        if (slidesElement.length === 0) return;

        currentIndex--;
        if (currentIndex < 0) {
            currentIndex = slidesElement.length - 1;
        }
        showSlide(currentIndex);
    };

    const onSlideUpdate = () => {
        slidesElement = document.getElementsByClassName(elementsName);
        if (slidesElement.length === 0) return;
        showSlide(currentIndex);
        if(!slideTimer) slideTimer = setInterval(nextSlide, slideTime);
    }

    const onSlideDestroy = () => {
        clearInterval(slideTimer);
    }

    useEffect(() => {
        onSlideUpdate();

        return () => {
            onSlideDestroy();
        }
    })

    return {
        slide,
        onSlideUpdate,
        onSlideDestroy,
        nextSlide,
        previousSlide
    }
}