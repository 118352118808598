import { useEffect, useRef } from "react";

export const LineWidthResponsive = (props: {parentClassName?: string, containerClassName?: string}) => {
    const { containerClassName } = props;

    let parent: Element | null = null 
    let width = 0
    const height = 1;

    const svgContent = useRef<any>(null)
    

    const handleResize = () => {
        let width = parent?.clientWidth;
        const svg = svgContent.current;
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`)
        svg.setAttribute('style', `width: ${width}px; height: ${height}px;`)

        const line = svg.querySelector('line');
        line.setAttribute('x2', `${width}`)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            handleResize()
        })

        parent = svgContent.current.parentElement;
        width = parent ? parent.clientWidth : 0;
        handleResize()
    }, [])

    return (
        <svg ref={svgContent} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" 
        style={{ width: `${width}px`, height: `${height}px` }} className={containerClassName}>
            <line x1="0" y1="0" x2={width} y2={height} stroke={"red"} strokeWidth={1} className={"line"} />
        </svg>
    )
}
