import ReactDOM from "react-dom"

import { IPopupSubscribe, PopupSubscribe } from "./Popup/Subscribe"

export const FloatSubcribe = (props: IPopupSubscribe) => {
    const open = () => {
        ReactDOM.render(<PopupSubscribe {...props} />, document.getElementById("popup"))
    }
    return (
        <button className="normalButton notify" onClick={open}>Notify</button>
    )
}