import { BaseImagePath } from '../../../Component/API'
import { IMediaItemProps, IMediaProps, LinkType } from '../Interface'
import { ViewMore } from '../Media'

export const RecentCards = (props: IMediaProps) => {
    const { data } = props
    return (
        <div id="recentCardsMedia" className="fullWidthContainer flexColumn">
            <div className="headerContainer style1">
                <div className="ornament">
                    <div className="left">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="center">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                </div>
                <h2>{data.section}</h2>
            </div>
            <div className="recentCards fullWidthContainer flexRow">
                {
                    data.items.map((item, index) => (
                        <RecentCard key={`${index}_${item.url}`} {...item} link_type={data.link_type} />
                    ))
                }
            </div>
            <ViewMore url={data.view_more_url} />
        </div>
    )
}

const RecentCard = (props: IMediaItemProps) => {
    const { image_url, url, title, description, link_type } = props

    return (
        <div
            className={`recentCard${link_type === LinkType.Other ? " link" : ""}`}
            {...(link_type === LinkType.Other) ?
                {
                    onClick: () => window.open(url, "_blank")
                }
                :
                {}
            }
        >
            <div className="imageContainer" style={{
                backgroundImage: `url(${BaseImagePath}${image_url})`
            }} >
                {
                    link_type === LinkType.Youtube &&
                    <iframe
                        width="100%"
                        height="100%"
                        src={url}
                        title={title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                }
            </div>
            <div className="contentContainer">
                <div className="title">
                    {title}
                </div>
                <p className="speaker">{description}</p>
            </div>
        </div>
    )
}