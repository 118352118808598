import { BaseImagePath } from '../../../Component/API'
import { IMediaProps, LinkType } from '../Interface'

export const NewReleaseCards = (props: IMediaProps) => {
    const { data } = props

    return (
        <div id="newReleaseMedia"
            className={`fullWidthContainer flexColumn${data.link_type === LinkType.Other ? " link" : ""}`}
            {...(data.link_type === LinkType.Other) ?
                {
                    onClick: () => window.open(data.items[0].url, "_blank")
                }
                :
                {}
            }
        >
            <div className="headerContainer style1">
                <div className="ornament">
                    <div className="left">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="center">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                </div>
                <h2>{data.section}</h2>
            </div>
            <div className="imageContainer" style={{
                backgroundImage: `${BaseImagePath}${data.items[0].image_url}`
            }}>
                {
                    data.link_type === LinkType.Youtube &&
                    <iframe
                        width="100%"
                        height="100%"
                        src={data.items[0].url}
                        title={data.items[0].title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                }
            </div>
            <div className="title">
                {data.items[0].title}
            </div>
            <p className="speaker">{data.items[0].description}</p>
        </div>
    )
}