import ReactDOM from "react-dom"
import { useHoverGlow } from "../../Effects/useHoverGlow"
import { Close } from "../Vector/Close"
import { useContactUs } from "../useContactUs"
import { PopupContainer } from "./PopupContainer"

export interface IPopupContactUs {
    extraInformation?: string
}

export const PopupContactUs = (props: IPopupContactUs) => {
    const close = () => {
        ReactDOM.render(<></>, document.getElementById("popup"))
    }
    
    const { email, name, setEmail, setName, setMessage, onSubmit } = useContactUs({
        extraInformation: props.extraInformation,
        popupClose: close
    })

    return (
        <>
            <PopupContainer>
                <div id="popupContactUs" className="section contactUs2">
                    <FrameBackground />
                    <Close />
                    <form onSubmit={onSubmit}>
                        <h3>Contact Us</h3>
                        <input type="text" placeholder="Name"
                            required
                            onChange={(e) => {
                                setName(e.target.value)
                            }} value={name} />
                        <input type="email" placeholder="Email"
                            required
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }} value={email} />
                        <textarea placeholder="Message"
                            required
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }} />
                        <button className="normalButton">SEND</button>
                    </form>
                </div>
            </PopupContainer>
        </>
    )
}

const FrameBackground = () => {
    useHoverGlow(".frameBackground", true)

    return (
        <>
            <div className="frameBackground thin inline left">
                <div className="roundedBackground colored" />
                <div className="square colored" />
            </div>

            <div className="frameBackground thin block top2">
                <div className="square colored" />
                <div className="roundedBackground colored" />
            </div>

            <div className="frameBackground thin inline right bottom">
                <div className="spacer" />
                <div className="roundedBackground colored" />
                <div className="square colored h-100" />
            </div>

            <div className="frameBackground thin block bottom colored" />

        </>
    )
}