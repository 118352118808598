import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import {
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    Navbar, NavbarBrand,
    NavbarToggler
} from 'reactstrap';
import { useHoverGlow } from '../Effects/useHoverGlow';
import langSelection from './../img/langSelection.svg';
import logo from './../logo.png';
import { TopMenuIcon } from './Vector/Title/TopMenuIcon';

const langIdPath = '/images/lang/id.png'
const langEnPath = '/images/lang/en.png'

const path = {
    getToKnowUs: "/get-to-know-us",
    ministries: "/ministries",
    letsGrow: "/lets-grow",
    events: "/events",
    media: "/media",
    give: "/give"
}

const TopNavMenu = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    useHoverGlow('.top-nav-menu')

    const toggleNavbar = () => setCollapsed(!collapsed);
    const toggleOff = () => setCollapsed(true);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Navbar className="top-nav-menu" expand="xl" fixed="top">
            <NavbarBrand onClick={() => {
                navigate('/')
                toggleOff()
            }}>
                <img id="logo" src={logo} alt="logo" />
            </NavbarBrand>

            <NavbarToggler onClick={toggleNavbar} className={`me-2 ${collapsed ? '' : 'show'}`}>
                <TopMenuIcon />
                <span className="navbar-toggler-text">Menu</span>
            </NavbarToggler>
            <Collapse isOpen={!collapsed} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink onClick={() => toggleOff()} to={path.getToKnowUs}>{t('topMenu.knowUs')}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => toggleOff()} to={path.ministries}>{t('topMenu.ministries')}</NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink onClick={() => toggleOff()} to={path.letsGrow}>{t("topMenu.grow")}</NavLink>
                    </NavItem> */}
                    <NavItem>
                        <NavLink onClick={() => toggleOff()} to={path.events}>{t('topMenu.events')}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => toggleOff()} to={path.media}>{t('topMenu.media')}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => toggleOff()} to={path.give}>{t('topMenu.give')}</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
            <Nav className="ml-auto account">
                <NavItem>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle nav>
                            <img src={ i18n.language === 'en' || i18n.language.match(/en.*/) ? langEnPath : langIdPath} className="langFlag" />
                            <img src={langSelection} className="langSelection" alt="Select Language" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => i18n.changeLanguage('en')}>
                                <img src={langEnPath} /> English
                            </DropdownItem>
                            <DropdownItem onClick={() => i18n.changeLanguage('id')}>
                                <img src={langIdPath} /> Indonesia
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            </Nav>
        </Navbar>
    );
};

export default TopNavMenu;