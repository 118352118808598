import { Socmed } from "../../../../Component/Socmed"
import { useContactUs } from "../../../../Component/useContactUs"
import { useHoverGlow } from "../../../../Effects/useHoverGlow"

export const SignIn = () => {
    useHoverGlow("#footer")
    const close = () => {
        setName("")
        setEmail("")
        setMessage("")
    }

    const { email, name, message, setEmail, setName, setMessage, onSubmit } = useContactUs({popupClose: close})

    return (
        <>
            <div id="signIn" className="section contactUs2">
                <SignInBackground />
                <div className="subsection">
                    <span>Get to know us better<span className="mobileHide">, kindly follow</span></span>
                    <Socmed />
                </div>
                <form onSubmit={onSubmit}>
                    <h3>Contact Us</h3>
                    <input type="text" placeholder="Name"
                        required
                        onChange={(e) => {
                            setName(e.target.value)
                        }} value={name} />
                    <input type="email" placeholder="Email"
                        required
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }} value={email} />
                    <textarea placeholder="Message"
                        required
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }} value={message} />
                    <button className="normalButton">SEND</button>
                </form>
            </div>
            <div id="footer" className="section" />
        </>
    )
}

const SignInBackground = () => {
    useHoverGlow(".frameBackground", true)

    return (
        <>
            <div className="frameBackground inline left">
                <div className="roundedBackground colored" />
                <div className="square colored" />
            </div>

            <div className="frameBackground block top">
                <div className="roundedBackground colored" />
                <div className="square colored" />
            </div>

            <div className="frameBackground inline right colored" />

            <div className="frameBackground block bottom colored" />

        </>
    )
}