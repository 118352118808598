import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    topMenu: {
                        knowUs: 'Get to know us!',
                        ministries: 'Ministries',
                        grow: "Let's Grow!",
                        events: 'Events',
                        media: 'Media',
                        give: 'Give',
                        account: 'Account',
                        signIn: 'Sign In',
                        language: 'Language',
                        english: 'English',
                        indonesia: 'Indonesia',
                    },
                    home: {
                        w: 'W',
                        elcome: 'ELCOME',
                        to: 'to',
                        gbiGilgalPik: ' GBI GILGAL PIK',
                        buildingAStrongChurch: 'Building a strong church',
                        description: 'Lorem Ipsum bla bla bla',
                        getToKnowUs: 'Get to know us',
                        familyCell: 'GBI Gilgal Family Cell is a spiritual family where you will be guided and discipled, specifically intended for church members who have attended at least 4 onsite services and have decided to be embedded in the local GBI GILGAL church.'
                    },
                    getToKnowUs: {
                        pastor: `A pastor is someone with the authority to lead religious services. Pastors lead church services and help others worship. Pastor is a religious title used mostly in Christian churches.
                        
The pastor is a leader within a church who has been ordained and therefore given the authority to conduct religious services.`
                    },
                    ministry: {
                        noLive: 'Not Available'
                    },
                    event: {
                        noEvent: 'No events.',
                    },
                    subscribe: {
                        unsubscribeConfirmation: 'Hi {name}, Are you sure want to unsubscribe from {ministries}?'
                    }
                }
            },
            id: {
                translation: {
                    topMenu: {
                        knowUs: 'Kenali Kami Lebih Dekat!',
                        ministries: 'Ibadah',
                        grow: "Let's Grow!",
                        events: 'Kegiatan',
                        media: 'Media',
                        give: 'Pemberian',
                        account: 'Account',
                        signIn: 'Sign In',
                        english: 'English',
                        indonesia: 'Indonesia'
                    },
                    home: {
                        familyCell: 'Family Cell GBI Gilgal adalah keluarga rohani dimana Saudara akan dibimbing dan dimuridkan, ditujukan khusus bagi jemaat yang telah hadir minimal 4 kali ibadah secara onsite yang telah memutuskan untuk tertanam dalam gereja lokal GBI GILGAL.'
                    },
                    getToKnowUs: {
                        pastor: `Seorang pendeta adalah seseorang yang memiliki otoritas untuk memimpin ibadah keagamaan. Pendeta memimpin ibadah gereja dan membantu orang lain dalam beribadah. Pendeta adalah gelar keagamaan yang digunakan terutama dalam gereja-gereja Kristen. 

Pendeta adalah seorang pemimpin dalam gereja yang telah diurapi dan dengan demikian diberikan otoritas untuk melakukan ibadah keagamaan.`
                    },
                    ministry: {
                        noLive: 'Tidak tersedia'
                    },
                    event: {
                        noEvent: 'Tidak ada event.',
                    },
                    subscribe: {
                        unsubscribeConfirmation: 'Halo {name}. Sudah yakin ingin menghapus notifikasi untuk {ministries}?'
                    }
                }
            }
        }
    });

export default i18n;