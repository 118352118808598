
export const TitleFlex = (props: {title: string, icon?: JSX.Element, className?: string}) => {
    const {title, icon, className} = props

    return (
        <h1 {...className ? {className} : {}}>
            <div className="hLine1" />
            <div className="hLine2" />
            {icon && icon}
            <span className="title">
                {title}
            </span>
        </h1>
    )
}