import { API, BaseImagePath } from "../../Component/API";
import { useGetData } from "../../Component/getData";

interface IGiveCard {
    type: string,
    title: string,
    description: string,
    account: string,
    account_number: string
    image_url?: string
}

export const Give = () => {
    const { data } = useGetData<IGiveCard[]>(API.give)

    return (
        <div id="give">
            <div className="fullWidthContainer giveContainer">
                <div id="giveCards">
                    {
                        data?.map((give, index) => (
                            <GiveCard key={index} {...give} />
                        ))
                    }

                </div>
            </div>
            <div style={{
                height: "300px"
            }} />
        </div>
    );
};

const GiveCard = (props: IGiveCard) => {
    const { type, title, description, account, account_number, image_url } = props;
    console.log(image_url)
    return (
        <div className="giveCard" style={{
            backgroundImage: `url(${BaseImagePath}${image_url})`
        }}>
            <div className="headerContainer style1">
                <div className="ornament">
                    <div className="left">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="center">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                </div>
                <h2>{type}</h2>
            </div>

            <div className="bodyContainer">
                <div className="body">
                    <div className="text">
                        <div className="title">
                            {title}
                        </div>
                        <div className="description">
                            {description}
                        </div>
                    </div>
                    <div className="info">
                        <div className="title">
                            ACCOUNT INFORMATION
                        </div>
                        <div className="account">
                            {account}
                        </div>
                        <div className="accountNumber">
                            {account_number}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Give;
