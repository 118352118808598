const dummyUrl = "/dummy_data/"
const dummyImagePath = ""

const realUrl = "https://api.gbigilgal.org/api/"
const realBaseImagePath = "https://api.gbigilgal.org/storage/"

const dummy = {
    home: dummyUrl + "home_{lang}.json",
    getToKnowUs: dummyUrl + "get_to_know_us_{lang}.json",
    ministries: dummyUrl + "ministries_{lang}.json",
    letsGrow: dummyUrl + "lets_grow_{lang}.json",
    events: dummyUrl + "events_{lang}.json",
    media: dummyUrl + "media_{lang}.json",
}

const real = {
    home: realUrl + "home?lang={lang}",
    getToKnowUs: realUrl + "get-to-know-us?lang={lang}",
    ministries: realUrl + "ministries-data?lang={lang}",
    letsGrow: dummyUrl + "lets_grow_{lang}.json",
    events: realUrl + "events?lang={lang}",
    media: realUrl + "medias",
    contactUs: realUrl + "contact-us",
    subscribe: realUrl + "subscribe",
    eventNotification: realUrl + "notify-event",
    unsubscribe: realUrl + "unsubscribe",
    give: realUrl + "gives?lang={lang}",
    link: realUrl + "links",
}

const API = real
const BaseImagePath = realBaseImagePath

export { API, BaseImagePath }

