export const PlatformData = (props: { className?: string }) => {
    const { className } = props
    return (
        <div className={`platformInfo data${className ? ' ' + className : ''}`}>
            <div className="info">
                <div className="left" />
                <div className="center">
                    <p>Platform <b>@account</b> | Days <b>7</b> pm</p>
                </div>
                <div className="right" />
            </div>
            <div className={`buttonContainer`}>
                <button className="normalButton notify">
                    Notify Me
                </button>
                <button className="normalButton register">
                    Register
                </button>
            </div>
        </div>
    )
}

export const PlatformDataNoButton = (props: { className?: string, info?: string }) => {
    const { className, info } = props

    if(!info) return <></>
    return (
        <div className={`platformInfo data${className ? ' ' + className : ''}`}>
            <div className="info">
                <div className="left" />
                <div className="center">
                    <p dangerouslySetInnerHTML={{
                        __html: info || ''
                    }}/>
                </div>
                <div className="right" />
            </div>
        </div>
    )
}

export const PlatformDataButtonOnly = (props: { className?: string }) => {
    const { className } = props
    return (
        <div className={`platformInfo data${className ? ' ' + className : ''}`}>
            <div className={`buttonContainer`}>
                <button className="normalButton notify">
                    Notify Me
                </button>
                <button className="normalButton register">
                    Register
                </button>
            </div>
        </div>
    )
}