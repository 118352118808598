import { IMediaItemProps, IMediaProps, LinkType } from '../Interface'
import { ViewMore } from '../Media'

export const PodcastCards = (props: IMediaProps) => {
    const { data } = props

    return (
        <div id="podcastCardsMedia" className="fullWidthContainer flexColumn">
            <div className="headerContainer style1">
                <div className="ornament">
                    <div className="left">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="center">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="lineContainer">
                            <div className="hLine1" />
                            <div className="hLine2" />
                        </div>
                    </div>
                </div>
                <h2>{data.section}</h2>
            </div>
            <div className="podcastCards fullWidthContainer flexRow">
                {
                    data.items.map((item, index) => (
                        <PodcastCard key={`${index}_${item.url}`} {...item} link_type={data.link_type} />
                    ))
                }
            </div>
            <ViewMore url={data.view_more_url} />
        </div>
    )
}

const PodcastCard = (props: IMediaItemProps) => {
    const { image_url, description, url, title, link_type } = props

    return (
        <div className={`podcastCard${link_type === LinkType.Other ? " link" : ""}`}
            {...(link_type === LinkType.Other) ?
                {
                    onClick: () => window.open(url, "_blank")
                }
                :
                {}
            }
        >
            <div className="imageContainer" style={{
                backgroundImage: `url(${image_url})`
            }}>
            </div>
            <div className="contentContainer">
                <div className="title">
                    {title}
                </div>
                <p>{description}</p>
            </div>
        </div>
    )
}