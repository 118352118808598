import ReactDOM from "react-dom"

import { IPopupContactUs, PopupContactUs } from "./Popup/ContactUs"

export const OpenContactUs = (extraInformation?: string) => {
    ReactDOM.render(<PopupContactUs extraInformation={extraInformation} />, document.getElementById("popup"))
}

export const FloatContactUs = (props: IPopupContactUs) => {
    return (
        <button className="normalButton" 
            onClick={ () => OpenContactUs(props.extraInformation)
        }>Contact Us</button>
    )
}