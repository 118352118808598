import leftPatternP1 from './../img/contactUs/leftPattern-p1.png';
import leftPatternP2 from './../img/contactUs/leftPattern-p2.png';
import leftPatternP3 from './../img/contactUs/leftPattern-p3.png';
import { ContactUsBackground } from './ContactUsBackground';
import { FloatContactUs } from './FloatContactUs';

export const ContactUs = () => {
    return (
        <div id="contactUs">
            <ContactUsBackground maxWidth={1300} />
            <div className="contactUsContainer">
                <div className="part">
                    <img src={leftPatternP1} />
                    <img src={leftPatternP2} />
                    <img src={leftPatternP3} />
                    <div className="word">
                        Got any question?
                    </div>
                </div>
                <div className="part">
                    <div className="word">
                        <span>Don’t hesitate to</span>
                        <FloatContactUs />
                    </div>
                </div>
            </div>
        </div>
    )
}