import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const hash = {
    newLife: '#newLifeAnchor',
    waterBaptism: '#waterBaptismAnchor',
    grow: '#growAnchor',
    membershipClass: '#membershipClassAnchor',
    familyCell: '#familyCellAnchor'

}

export const Menu = () => {
    const location = useLocation()

    const [selectedHash, setSelectedHash] = useState(location.hash !== '' ? location.hash : hash.newLife)

    useEffect(() => {
        setSelectedHash(location.hash !== '' ? location.hash : hash.newLife)
    }, [location.hash])

    // list of menu from hash
    return (
        <div id="letsGrowMenu">
            <ul className="menu">
                <li><a href={hash.newLife}
                    className={selectedHash === hash.newLife ? 'selected' : ''}
                >New Life</a></li>

                <li><a href={hash.waterBaptism}
                    className={selectedHash === hash.waterBaptism ? 'selected' : ''}
                >Baptism</a></li>

                <li><a href={hash.grow}
                    className={selectedHash === hash.grow ? 'selected' : ''}
                >Grow</a></li>

                <li><a href={hash.membershipClass}
                    className={selectedHash === hash.membershipClass ? 'selected' : ''}
                >Membership Class</a></li>

                <li><a href={hash.familyCell}
                    className={selectedHash === hash.familyCell ? 'selected' : ''}
                >Family Cell</a></li>
            </ul>
        </div>
    )
}