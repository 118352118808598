
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactUs } from '../../../../Component/ContactUs';
import { Line } from '../../../../Component/Line';
import { Pagination } from '../../../../Component/Pagination';
import { Socmed } from '../../../../Component/Socmed';
import { useSlide } from '../../../../Component/useSlide';
import { useHoverGlow } from '../../../../Effects/useHoverGlow';

interface ILanding {
    slide?: string[]
}

export const Landing = (props: ILanding) => {
    const { slide, previousSlide, nextSlide, onSlideUpdate } = useSlide(props.slide ?? [], "slide")
    const navigate = useNavigate()
    useHoverGlow('#landing')

    useEffect(() => {
        onSlideUpdate()
    }, [props.slide])

    return (
        <div id="landing" className="section">
            <div id="welcome">
                <div className="wContainer">
                    <Line width={950} height={1} containerClassName={"hLine1"} />
                    <Line width={980} height={1} containerClassName={"hLine2"} />
                    <div id="dotYellow" />

                    <Line width={96} height={1} containerClassName={"crossHLine"} />
                    <div id="crossVLine" />
                    <Line width={1} height={300} containerClassName={"crossVLine"} />

                    <div id="dotBlue" />

                    <Line width={469} height={1} containerClassName={"hLine3"} />
                    
                    <Line width={1} height={136} containerClassName={"vLine1"} />
                    <Line width={1} height={160} containerClassName={"vLine2"} />

                    <div id="welcomeText">
                        <span id="wText">W</span>
                        <span id="elcomeText">ELCOME</span>
                    </div>
                </div>
            </div>

            <div id="leftBackground" />

            <div id="homepageSlide">
                <div id="mask" className="slideContainer" />

                <div className="slideContainer">
                    <div id="homepageSlideTitle">
                        <span id="to">to</span> <span id="gilgalPik">GBI GILGAL PIK</span>
                    </div>
                    <div id="homepageSlideContent">
                        <div id="motto">
                            be a strong church
                        </div>

                        <div id="content">
                            {
                                slide.map((text, index) => {
                                    return (
                                        <p key={index} className="slide">
                                            {text}
                                        </p>
                                    )
                                })
                            }
                        </div>

                        <button className="uniqueButton" onClick={() => {
                            navigate("/get-to-know-us")
                        }}>
                            <div className="part"/>
                            <div className="part">
                                Get to know us!
                            </div>
                            <div className="part"/>
                        </button>

                    </div>
                </div>

                <Line width={1} height={161} containerClassName={"vLine3"} />

                <Socmed />

                <Pagination id={"homepageSlideNav"} 
                    prevSlide={previousSlide}
                    nextSlide={nextSlide}
                />
            </div>

            <ContactUs />
        </div>
    )
    
}