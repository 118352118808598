import queryString from "query-string"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { API } from "../../Component/API"
import { TopMenuCategories } from "../../Component/TopMenuCategories"
import { useGetData } from "../../Component/getData"
import { DisplayType, IMedia, MediaCategory } from "./Interface"
import { NewReleaseCards } from "./Section/NewReleaseCards"
import { PodcastCards } from "./Section/PodcastCards"
import { RecentCards } from "./Section/RecentCards"
import { ShortsCards } from "./Section/ShortsCards"

export const Media = () => {
    const { data } = useGetData<IMedia[]>(API.media)
    const [medias, setMedias] = useState<IMedia[]>([])

    const location = useLocation()
    const navigate = useNavigate()
    const search = queryString.parse(location.search)

    const categories = [
        { label: MediaCategory.Sermons.toUpperCase(), url: MediaCategory.Sermons },
        { label: MediaCategory.Originals.toUpperCase(), url: MediaCategory.Originals },
        { label: MediaCategory.Worship.toUpperCase(), url: MediaCategory.Worship }
    ]

    useEffect(() => {
        if (search.category == undefined) {
            navigate(`/media?category=${MediaCategory.Sermons}`)
            return
        }

        updateMedia()
    }, [data, search.category])

    const updateMedia = () => {
        if (!data) return

        setMedias(data.filter((media) => {
            return media.category === search.category
        }))
    }

    return (
        <div id="media">
            <TopMenuCategories baseUrl="media" categories={categories} parameterName={"category"} />
            <div className="fullWidthContainer mediaContainer">
                {medias.map((media) => {
                    if (media.display_type === DisplayType.NewRelease)
                        return <NewReleaseCards data={media} />
                    else if (media.display_type === DisplayType.Shorts)
                        return <ShortsCards data={media} />
                    else if (media.display_type === DisplayType.Podcast)
                        return <PodcastCards data={media} />

                    return <RecentCards data={media} />
                })}
            </div>
            <div style={{
                height: "300px"
            }} />
        </div>
    )
}

export const ViewMore = (props: {url?: string}) => {
    const { url } = props
    
    return (
        <div className="viewMore fullWidthContainer flexRow">
            <div className="line" />
            <a href={url} target="_blank">
                <div className="text">
                // VIEW MORE
                </div>
            </a>
        </div>

    )
}