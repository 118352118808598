import { TitleFlex } from "../../../Component/TitleFlex";
import { Icon } from "../../../Component/Vector/Title/Icon";
import { PlatformData } from "../Component/PlatformData";
import { INewLifeData } from "../Interface/NewLife";

export const NewLife = (props: {data?: INewLifeData}) => {
    const { title, description, cards } = props.data ?? {
        title: "-",
        description: "-",
        cards: []
    };

    return (
        <div id="newLifeContainer" className="fullWidthContainer darkBackground letsGrowSection">
            <div className="anchorPoint" id="newLifeAnchor" />
            <div className="fullWidthContainer">
                <TitleFlex title={title} icon={<Icon />} />
            </div>
            <div className="padContainer">
                <p className="description">
                    <span className="firstCharacter">{description.charAt(0)}</span>
                    {description.slice(1)}
                </p>

                <div className="newLifeCards flexRow">
                    <div className="flexRow">
                        {cards.map((card, index) => (
                            <NewLifeCard
                                key={index}
                                className={card.className}
                                title={card.title}
                                description={card.description}
                                image={card.image}
                            />
                        ))}
                    </div>
                </div>

                <PlatformData />
            </div>
        </div>
    );
};

const NewLifeCard = (props: {title: string, description: string, image: string, className: string}) => {
    const {title, description, image, className} = props

    return (
        <div className={`newLifeCard ${className}`}>
            <div className="image" style={{backgroundImage: `url(${image})`}} />
            <div className="contentContainer">
                <div className="title">
                    {title.split(" ")[0]}<br />
                    {title.split(" ")[1]}
                </div>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}