import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export interface IMenu {
    label: string,
    url: string
}

export const Menu = (props: {
    sectionName?: string
    menus: IMenu[]
}) => {
    const { sectionName, menus } = props
    const location = useLocation()
    const [selectedHash, setSelectedHash] = useState('')

    useEffect(() => {
        if(menus.length === 0) return
        setSelectedHash(location.hash !== '' ? location.hash : `#${menus[0].url}`)
    }, [location.hash, menus])
    
    if(menus.length === 0) return (<></>)


    return (
        <div id="ministriesMenu">
            <ul className="menu">
                { menus.map((menu) => {
                    return (
                        <li key={sectionName + "_" + menu.url}>
                            <a href={`#${menu.url}`} className={selectedHash === `#${menu.url}` ? 'selected' : ''}>
                                {menu.label}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}