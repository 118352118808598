import { useTranslation } from 'react-i18next'
import specialButtonIcon from './../img/card/specialButton/icon.svg'
import { FloatSubcribe } from './FloatSubscribe'
import { IPopupSubscribe } from './Popup/Subscribe'

export const SpecialButton2 = (props: IPopupSubscribe) => {
    const { t } = useTranslation()
    return (
        <div className="specialButton">
            <img src={specialButtonIcon} />
            <div className="line" />

            <div className="centerContainer">
                <FloatSubcribe {...props} />
                <button className="normalButton join"
                    {...!(props.join_link && props.join_link_enable) && { disabled: true, title: t("ministry.noLive").toString() }}
                    onClick={() => {
                        window.open(props.join_link, "_blank")
                    }}
                >
                    Join!
                </button>
            </div>
        </div>
    )
}