import queryString from "query-string"
import { useLocation, useNavigate } from "react-router-dom"

interface TopMenuCategoriesProps {
    categories: {
        label: string,
        url: string
    }[]
    baseUrl: string
    parameterName: string
}

export const TopMenuCategories = (props: TopMenuCategoriesProps) => {
    const { categories, baseUrl, parameterName } = props

    const navigate = useNavigate()
    const location = useLocation()
    const search = queryString.parse(location.search)

    const onSelect = (e: any, url: string) => {
        document.getElementsByClassName("selected")[0].classList.remove("selected")
        e.target.classList.add("selected")

        let params = "";
        if (search.hideTopMenu == "true") {
            params = "&hideTopMenu=true"
        }

        navigate(`/${baseUrl}?${parameterName}=${url.replace(" ", "-").toLowerCase()}${params}`)
    }

    return (
        <div id="topMenuCategories">
            <div className="topMenuCategoriesContainer">
                {categories.map((category, index) => {
                    return (
                        <div
                            className={`category${(search[`${parameterName}`] == category.url.toLowerCase() ? ' selected' : '')}`}
                            onClick={(e) => {
                                onSelect(e, category.url)
                            }}
                            key={index}>
                            {category.label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}