import { useEffect, useRef } from "react";

export const LineHeightResponsive = (props: {parentClassName?: string, containerClassName?: string, sm?: number, lg?: number, maxHeight?: number}) => {
    const { containerClassName } = props;

    let parent: Element | null = null 
    let height = 0
    let sm = props.sm ?? 0;
    let lg = props.lg ?? props.sm ?? 0;

    const width = 1;

    const svgContent = useRef<any>(null)
    

    const handleResize = () => {
        let height = parent?.clientHeight ?? 0;
        
        if(window.innerWidth < 558) {
            height -= sm;
        }
        else {
            height -= lg;
        }

        if(props.maxHeight) {
            height = Math.min(height, props.maxHeight);
        }

        const svg = svgContent.current;
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`)
        svg.setAttribute('style', `width: ${width}px; height: ${height}px;`)

        const line = svg.querySelector('line');
        line.setAttribute('y2', `${height}`)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            handleResize()
        })

        parent = svgContent.current.parentElement;
        height = parent ? parent.clientHeight : 0;
        handleResize()
    }, [])

    return (
        <svg ref={svgContent} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" 
        style={{ width: `${width}px`, height: `${height}px` }} className={containerClassName}>
            <line x1="0" y1="0" x2={width} y2={height} stroke={"red"} strokeWidth={1} className={"line"} />
        </svg>
    )
}
