import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = () => {
  const { pathname, hash } = useLocation();

  // @ts-ignore
  const behavior: ScrollBehavior = "instant"

  useEffect(() => {
    if(!hash) window.scrollTo({
      top: 0,
      behavior: behavior
    });
  }, [pathname, hash]);

  return null;
}