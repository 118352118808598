import ReactDOM from "react-dom"

export const PopupContainer = (props: { children: JSX.Element }) => {
    const { children } = props

    const close = () => {
        ReactDOM.render(<></>, document.getElementById("popup"))
    }

    return (
        <>
            <div id="popupContainer">
                <div id="popupBackground" onClick={close}></div>
                <div id="popupContent">
                    {children}
                </div>
            </div>
        </>
    )
}