import { LineWidthResponsive } from "./LineWidthResponsive"

export const Title = (props: {title: string, icon?: JSX.Element}) => {
    const {title, icon} = props

    return (
        <h1>
            <div className="h1Border">
                <LineWidthResponsive parentClassName="h1Border" containerClassName="h1hLine1" />
                <LineWidthResponsive parentClassName="h1Border" containerClassName="h1hLine2" />
            </div>
            <span className="title">
                {title}
                { icon && icon }
            </span>
        </h1>
    )
}