import { useState } from "react"
import ReactDOM from "react-dom"
import { toast } from "react-toastify"
import { useHoverGlow } from "../../Effects/useHoverGlow"
import { API } from "../API"
import { Close } from "../Vector/Close"
import { PopupContainer } from "./PopupContainer"

export interface IPopupEvent {
    event_id: number
    title?: string
}

export const PopupEvent = (props: IPopupEvent) => {
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")

    const close = () => {
        ReactDOM.render(<></>, document.getElementById("popup"))
    }

    return (
        <>
            <PopupContainer>
                <div id="popupSubscribe" className="section subscribe">
                    <FrameBackground />
                    <Close />
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setTimeout(async () => {
                            const response = await fetch(`${API.eventNotification}`, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    name, email, event_id: props.event_id
                                })
                            })

                            console.log(response.status)
                            if (response.status === 200) {
                                toast.success("We will notify you 1 day before the event.", {
                                    autoClose: 500,
                                    hideProgressBar: true,
                                    theme: "dark"
                                })
                                close()
                            }
                            else {
                                toast.error("Failed to request", {
                                    autoClose: 500,
                                    hideProgressBar: true,
                                    theme: "dark"
                                })
                            }
                        }, 0)
                    }}>
                        <h3>Notify {props.title ?? "Ministry" } Event</h3>
                        <input type="text" placeholder="Name"
                            required
                            onChange={(e) => {
                                setName(e.target.value)
                            }} value={name} />
                        <input type="email" placeholder="Email"
                            required
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }} value={email} />
                        <button className="normalButton">Notify Me</button>
                    </form>
                </div>
            </PopupContainer>
        </>
    )
}

const FrameBackground = () => {
    useHoverGlow(".frameBackground", true)

    return (
        <>
            <div className="frameBackground thin inline left">
                <div className="roundedBackground colored" />
                <div className="square colored" />
            </div>

            <div className="frameBackground thin block top2">
                <div className="square colored" />
                <div className="roundedBackground colored" />
            </div>

            <div className="frameBackground thin inline right bottom">
                <div className="spacer" />
                <div className="roundedBackground colored" />
                <div className="square colored h-100" />
            </div>

            <div className="frameBackground thin block bottom colored" />

        </>
    )
}