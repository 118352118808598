import ReactDOM from "react-dom"

import { IPopupEvent, PopupEvent } from "./Popup/Event"

export const FloatEventNotify = (props: IPopupEvent) => {
    const open = () => {
        ReactDOM.render(<PopupEvent {...props} />, document.getElementById("popup"))
    }
    return (
        <button className="normalButton notifyMe" onClick={open}>Notify</button>
    )
}