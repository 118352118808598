import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export async function getData<T>(url: string): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
        setTimeout(async () => {
            try {
                const response = await fetch(`${url}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data: T = await response.json();
                resolve(data);
            } catch (error) {
                console.error(error);
                resolve(undefined);
            }
        }, 0)
    })
}

export const useGetData = <T,>(url: string) => {
        
    const [data, setData] = useState<T | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const {i18n} = useTranslation();

    useEffect(() => {
        setLoading(true)
        getData<T>(url.replace("{lang}", normalizeLanguageCode(i18n.language))).then((data) => {
            setData(data)
            setLoading(false)
        })
    }, [i18n.language])

    return { data, loading }
}

const normalizeLanguageCode = (code: string) => {
    if (code.includes('-')) {
      return code.split('-')[0];
    }
    return code;
  };