import ReactDOM from "react-dom"

export const Close = () => {
    const close = () => {
        ReactDOM.render(<></>, document.getElementById("popup"))
    }

    return (
        <div className="buttonClose" onClick={close}>
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_815_9391)">
                    <circle cx="22" cy="18" r="16" fill="#DC2626" />
                    <circle cx="22" cy="18" r="17" stroke="#FBFBFB" stroke-width="2" />
                </g>
                <rect x="28.2219" y="8.71469" width="3.2" height="20.8" rx="1.6" transform="rotate(45 28.2219 8.71469)" fill="#FBFBFB" />
                <rect x="13.5125" y="10.9774" width="3.2" height="20.8" rx="1.6" transform="rotate(-45 13.5125 10.9774)" fill="#FBFBFB" />
                <defs>
                    <filter id="filter0_d_815_9391" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_815_9391" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_815_9391" result="shape" />
                    </filter>
                </defs>
            </svg>
        </div>
    )
}