import { useEffect } from "react"

export const useHoverGlow = (selector: string, all: boolean = false ) => {
    const onMouseMoveHoverGlow = (e: Event) => {
        const event = e as MouseEvent
        const element = event.target as HTMLElement
        const rect = element.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        element.style.setProperty('--mouse-x', x + 'px')
        element.style.setProperty('--mouse-y', y + 'px')
    }

    useEffect(() => {
        if (all) return

        const element = document.querySelector(selector);
        element?.addEventListener('mousemove', onMouseMoveHoverGlow)

        return () => {
            element?.removeEventListener('mousemove', onMouseMoveHoverGlow)
        }
    }, [])

    useEffect(() => {
        if(!all) return

        const elements = document.querySelectorAll(selector);
        elements.forEach(element => {
            element.addEventListener('mousemove', onMouseMoveHoverGlow)
        })

        return () => {
            elements.forEach(element => {
                element.removeEventListener('mousemove', onMouseMoveHoverGlow)
            })
        }
    }, [])
}