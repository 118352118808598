import fb from './../img/socialMedia/fb.svg';
import ig from './../img/socialMedia/ig.svg';
import tk from './../img/socialMedia/tk.svg';
import yt from './../img/socialMedia/yt.svg';
import { API } from './API';
import { useGetData } from './getData';

interface ILink {
    instagram_url: string
    facebook_url: string
    tiktok_url: string
    youtube_url: string
}


export const Socmed = () => {
    const { data } = useGetData<ILink>(API.link)
    

    return (
        <div className="socmedIcon">
            <button onClick={() => {
                window.open(data?.instagram_url || '')
            }}>
                <img src={ig} />
            </button>
            <button onClick={() => {
                window.open(data?.tiktok_url || '')
            }}>
                <img src={tk} />
            </button>
            <button onClick={() => {
                window.open(data?.facebook_url || '')
            }}>
                <img src={fb} />
            </button>
            <button onClick={() => {
                window.open(data?.youtube_url || '')
            }}>
                <img src={yt} />
            </button>
        </div>
    )
}