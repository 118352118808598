import { TitleFlex } from "../../../Component/TitleFlex";
import { Icon } from "../../../Component/Vector/Title/Icon";
import { PlatformData } from "../Component/PlatformData";
import { IWaterBaptismData } from "../Interface/WaterBaptism";


export const WaterBaptism = (props: { data?: IWaterBaptismData }) => {
    const { title, description, bibleVerse, description2, image } = props.data ?? {
        title: "-",
        description: "-",
        bibleVerse: "-",
        description2: "-",
        image: ""
    };

    return (
        <div id="waterBaptism" className="fullWidthContainer letsGrowSection">
            <div className="anchorPoint" id="waterBaptismAnchor" />
            <div className="fullWidthContainer">
                <TitleFlex title={title} className="light" icon={<Icon />} />
            </div>
            <div className="padContainer">
                <div className="flexRow baptismData">
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    />
                    <div className="flexColumn">
                        <p dangerouslySetInnerHTML={{
                            __html: description
                        }}>
                        </p>
                        <div
                            className="imageMobile"
                            style={{
                                backgroundImage: `url(${image})`,
                            }}
                        />
                        <p className="bibleVerse">
                            {bibleVerse}
                        </p>
                        <p>
                            {description2}
                        </p>
                    </div>
                </div>

                <PlatformData className="light" />
            </div>
        </div>
    );
};
