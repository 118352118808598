import { useState } from "react"
import { toast } from "react-toastify"
import { API } from "./API"

export const useContactUs = (props: { extraInformation?: string, popupClose?: () => void }) => {
    const { popupClose } = props
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [message, setMessage] = useState<string>("")

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setTimeout(async () => {
            var composedMessage = message
            if(props.extraInformation) {
                composedMessage = props.extraInformation + "\n\n" + composedMessage
            }
            const response = await fetch(`${API.contactUs}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name, email, message: composedMessage
                })
            })

            console.log(response.status)
            if (response.status === 200) {
                toast.success("Message sent", {
                    autoClose: 500,
                    hideProgressBar: true,
                    theme: "dark"
                })
                popupClose && popupClose()
            }
            else {
                toast.error("Failed to send message", {
                    autoClose: 500,
                    hideProgressBar: true,
                    theme: "dark"
                })
            }
        }, 0)
    }

    return {name, email, message, setName, setEmail, setMessage, onSubmit}
}