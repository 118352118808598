import { useEffect } from "react";
import { BaseImagePath } from "../../../../Component/API";
import { Pagination } from "../../../../Component/Pagination2";
import { Title } from "../../../../Component/Title";
import { useSlide } from "../../../../Component/useSlide";
import { useHoverGlow } from "../../../../Effects/useHoverGlow";
import { ILeader } from "../../HomeController";
import icon from './../../../../img/title/icon3.svg';

export const WeAreFamily = (props: {slide?: ILeader[]}) => {
    const { slide, onSlideUpdate, previousSlide, nextSlide } = useSlide(props.slide ?? [], "leaderCard")

    useEffect(() => {
        onSlideUpdate()
    }, [props.slide])

    useHoverGlow("#weAreFamily")
    
    return (
        <div id="weAreFamily" className="section">
            <Title title={"WE ARE FAMILY"} icon={icon} />

            <div id="leadersCards">
                {
                    slide?.map((leader, index) => {
                        return (
                            <LeaderCard key={index} data={leader} />
                        )
                    })
                }
            </div>

            <div className="centerContainer">
                <Pagination id={"weAreFamilyPageNav"}
                    prevSlide={previousSlide}
                    nextSlide={nextSlide}
                />
            </div>
        </div>
    )
}

const LeaderCard = (props: { data: ILeader }) => {
    const { data } = props
    return (
        <div className="card leaderCard">
            <div className="cardShadow" />
            <div className="cardContent">
                <div className="imageContainer" style={{
                    backgroundImage: `url(${BaseImagePath}${data.image_url})`
                }}>
                    {/* <img src={example} /> */}
                </div>
                <div className="textContainer">
                    <div className="name">
                        { data.name }
                    </div>
                    <div className="description">
                        <div className="title">
                            { data.title }
                        </div>
                        <p>
                            { data.long_description }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}