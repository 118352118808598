import queryString from 'query-string';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import TopNavMenu from "./Component/TopNavMenu";
import { useScrollToTop } from './Component/useScrollToTop';
import { useTitleUpdater } from './Component/useTitleUpdater';
import { Events } from './Page/Events/Events';
import { GetToKnowUs } from './Page/GetToKnowUs/GetToKnowUs';
import { Give } from './Page/Give/Give';
import { Home } from "./Page/Home/Home";
import { LetsGrow } from './Page/LetsGrow/LetsGrow';
import { Media } from './Page/Media/Media';
import { Ministries } from './Page/Ministries/Ministries';
import { Unsubscribe } from './Page/Unsubscribe/Unsubscribe';

function App() {
  useTitleUpdater()
  useScrollToTop()
  const location = useLocation()
  const search = queryString.parse(location.search)

  useEffect(() => {

    const body = document.getElementsByTagName("body")[0]
    if (search.ministry == "adult") {
      body.classList.add("dark")
    }
    else {
      body.classList.remove("dark")
    }
  }, [location.search])

  return (
    <div className="app">
      {search.hideTopMenu == "true" ? null : <TopNavMenu />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/get-to-know-us" element={<GetToKnowUs />} />
        <Route path="/ministries" element={<Ministries />} />
        <Route path="/lets-grow" element={<LetsGrow />} />
        <Route path="/events" element={<Events />} />
        <Route path="/media" element={<Media />} />
        <Route path="/give" element={<Give />} />
        <Route path="/unsubscribe/:code" element={<Unsubscribe />} />
      </Routes>
      <BackToTop />
      <div id="popup">
      </div>
    </div>
  );
}

export default App;

const BackToTop = () => {
  const onScroll = () => {
    if (window.scrollY > 200) {
      document.getElementById("backToTop")?.classList.remove("hide")
    }
    else {
      document.getElementById("backToTop")?.classList.add("hide")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    onScroll()

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <div id="backToTop" className='hide' onClick={() => {
      window.scrollTo(0, 0)
    }}>
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0L17.6603 12H0.339746L9 0Z" fill="#BDBDBD" className="backToTopColor" />
      </svg>

    </div>
  )
}