import { t } from "i18next"
import queryString from "query-string"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { API, BaseImagePath } from "../../Component/API"
import { ContactUs } from "../../Component/ContactUs"
import { FloatContactUs } from "../../Component/FloatContactUs"
import { FloatSubcribe } from "../../Component/FloatSubscribe"
import { Socmed } from "../../Component/Socmed"
import { SpecialButton2 } from "../../Component/SpecialButton2"
import { Title } from "../../Component/Title3"
import { TopMenuCategories } from "../../Component/TopMenuCategories"
import { Icon } from "../../Component/Vector/Title/Icon"
import { useGetData } from "../../Component/getData"
import { useUpdateLinkOnScroll } from "../../Component/useUpdateLinkOnScroll"
import { IMenu, Menu } from "./Component/Menu"
import { IMinistry, IMinistryCategory } from "./Interfaces"

export const Ministries = () => {
    const { init, deinit } = useUpdateLinkOnScroll(".sticky", true, 100, 350)
    const location = useLocation()
    const navigate = useNavigate()
    const search = queryString.parse(location.search)

    const { data } = useGetData<IMinistryCategory[]>(API.ministries)
    const [menus, setMenus] = useState<IMenu[]>([])
    const [ministries, setMinistries] = useState<IMinistry[]>([])

    const categories = data?.map((category) => {
        return {
            label: category.ministry_detail.menu_label,
            url: category.category
        }
    }) ?? []

    useEffect(() => {
        deinit()
        if (!data || data.length === 0) return
        if (search.ministry == undefined) {
            let params = "";
            if(search.hideTopMenu == "true") {
                params = "&hideTopMenu=true"
            } 
            navigate("/ministries?ministry=" + data[0].category + params, { replace: true })
            return
        }

        const index = data.findIndex((category) => category.category === search.ministry)
        if (index < 0) return

        setMenus(data[index].ministry_detail.ministries?.filter((ministry) => {
            if(ministry.link) return true
            return false
        }).map(
            (ministry) => ({
                label: ministry.menu,
                url: ministry.link
            })
        ) ?? [])

        setMinistries(data[index].ministry_detail.ministries ?? [])
    }, [search.ministry, data])

    useEffect(() => {
        init()
    }, [menus])

    if (!data) return <></>

    return (
        <div id="ministries">
            <div id="hideTopMenuBlock" />
            <TopMenuCategories baseUrl="ministries" categories={categories} parameterName="ministry" />
            <div id="leftNavigation">
                <Menu menus={menus} />
                <div className="socmedContainer">
                    <FloatContactUs />
                    <Socmed />
                </div>
            </div>

            <div className="ministryContainer">
                <MinistryCards ministries={ministries} />
            </div>
            <ContactUs />
            <div style={{
                height: "300px"
            }} />
        </div>
    )
}

const MinistryCards = (props: { ministries: IMinistry[] }) => {
    const { ministries } = props

    return (
        <div id="ministryCards">
            {
                ministries.map((ministry, index) => (
                    <MinistryCard key={index} ministry={ministry} style={(index + 1) % 2 == 0 ? "even" : "odd"} />
                ))
            }
        </div>
    )
}

const MinistryCard = (props: { ministry: IMinistry; style?: string }) => {
    const { ministry, style } = props;

    const { title, image_url, content, info, link } = ministry;

    return (
        <div className={`ministryCard${style ? ' ' + style : ''}${link ? ' sticky' : ''}`}>
            <div className="heading">
                <div className="hLine1" />
                <div className="hLine2" />

                <div className="ornament">
                    <div className="left" />
                    <div className="center" />
                    <div className="right" />
                </div>
                <Title title={title} icon={<Icon />} />
            </div>
            {link && <div className="anchorPoint" id={`${link}`} />}
            <div className="body">
                <div className="bodyContainer">
                    <div className="spacer leftBorder" />
                    <div className="leftContainer">
                        {/* <div className="leftBorder" /> */}
                        <div className="cardContent">
                            <div
                                className="cardContentImageMobile"
                                style={{ backgroundImage: `url(${BaseImagePath}${image_url})` }}
                            />
                            <div className="cardContentData">
                                <div className="title">{content.title}</div>
                                <div className="tagline">{content.tagline}</div>
                                <p>{content.short_description}</p>
                                {/* <a href="#" className="link">// read more</a> */}
                            </div>
                        </div>

                        {info &&
                            <>
                                <div className="spacer96 margin64 leftBorderMirror" />
                                <div className="data">
                                    <div className="info">
                                        <div className="left" />
                                        <div className="center" dangerouslySetInnerHTML={{
                                            __html: info ?? ""
                                        }}>
                                        </div>
                                        <div className="right" />
                                    </div>
                                    <div className={`specialButtonMobile${style ? ' ' + style : ''}`}>
                                        <div className="centerContainer">
                                            <FloatSubcribe 
                                                subscribe_type_id={ministry.subscribe_type_id} 
                                                title={ministry.menu}
                                            />
                                            <button className="normalButton join"
                                                {...!(ministry.join_link && ministry.join_link_enable) && { disabled: true, title: t("ministry.noLive").toString() }}
                                                onClick={() => {
                                                    window.open(ministry.join_link, "_blank")
                                                }}
                                            >Join!</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="rightContainerShadow" />
                    <div
                        className="rightContainer"
                        style={{
                            backgroundImage: `url(${BaseImagePath}${image_url})`,
                        }}
                    >
                        {/* <div className="imageContainer"  /> */}
                    </div>
                    <SpecialButton2 
                        subscribe_type_id={ministry.subscribe_type_id} 
                        title={ministry.menu} 
                        join_link={ministry.join_link}
                        join_link_enable={ministry.join_link_enable}
                    />
                </div>
            </div>
        </div>
    );
};