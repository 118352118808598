import leftArrow from './../img/leftArrow2.svg';
import rightArrow from './../img/rightArrow2.svg';
import { BasePagination, IBasePaginationControl, IBasePaginationID } from './BasePagination';

export const Pagination = (props: IBasePaginationID & IBasePaginationControl) => {
    const { id, prevSlide, nextSlide } = props;

    return (
        <BasePagination id={id} leftArrow={leftArrow} rightArrow={rightArrow}
            prevSlide={prevSlide} nextSlide={nextSlide}
        />
    )
}