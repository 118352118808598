export const Line = (props: { width: number, height: number, containerClassName?: string}) => {
    const { width, height, containerClassName } = props;

    return (
        <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" 
        style={{ width: `${width}px`, height: `${height}px` }} className={containerClassName}>
            <line x1="0" y1="0" x2={width} y2={height} stroke={"red"} strokeWidth={1} className={"line"} />
        </svg>
    )
}
