
export interface IBasePaginationID {
    id: string
}
export interface IBasePagination {
    leftArrow: string
    rightArrow: string
}

export interface IBasePaginationControl {
    prevSlide?: () => void
    nextSlide?: () => void
}

export const BasePagination = (props: IBasePaginationID & IBasePagination & IBasePaginationControl) => {
    const { id, leftArrow, rightArrow, prevSlide, nextSlide } = props;

    return (
        <div id={id} className="pagination">
            <button onClick={() => {
                prevSlide && prevSlide()
            }}>
                <img src={leftArrow} />
            </button>
            <button onClick={() => {
                nextSlide && nextSlide()
            }}>
                <img src={rightArrow} />
            </button>
        </div>
    )
}