import { TitleFlex } from "../../../Component/TitleFlex"
import { Icon } from "../../../Component/Vector/Title/Icon"
import { PlatformDataNoButton } from "../Component/PlatformData"
import { IMembershipClassData } from "../Interface/MembershipClass"

export const MembershipClass = (props:{data?: IMembershipClassData}) => {
    const { data } = props

    if(!data) return (
        <div id="membershipClass" className="fullWidthContainer letsGrowSection">
            <div className="anchorPoint" id="membershipClassAnchor" />
        </div>
    )

    return (
        <div id="membershipClass" className="fullWidthContainer letsGrowSection">
            <div className="anchorPoint" id="membershipClassAnchor" />
            <div className="fullWidthContainer">
                <TitleFlex title="Membership Class" className="light" icon={<Icon />} />
            </div>
            <div className="padContainer">
                <div className="flexRow membershipContainer">
                    <div className="flexColumn imageContainer">
                        <div className="image" style={{
                            backgroundImage: `url(${data.image})`
                        }} />
                        <PlatformDataNoButton className="light" />
                    </div>
                    <div className="flexColumn contentContainer">
                        <p className="upperContent" dangerouslySetInnerHTML={{
                            __html: data.upperContent
                        }} />
                        <div className="imageMobile" style={{
                            backgroundImage: `url(${data.image})`
                        }} />
                        <div className="lowerContent" dangerouslySetInnerHTML={{
                            __html: data.lowerContent
                        }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}