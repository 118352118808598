import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BaseImagePath } from '../../../../Component/API'
import { Line } from '../../../../Component/Line'
import { Pagination } from '../../../../Component/Pagination'
import { SpecialButton } from '../../../../Component/SpecialButton'
import { Title } from '../../../../Component/Title'
import { useSlide } from '../../../../Component/useSlide'
import { useHoverGlow } from '../../../../Effects/useHoverGlow'
import { IService } from '../../HomeController'
import icon from './../../../../img/title/icon.svg'

export const OurServices = (props: { data?: IService[] }) => {
    const { slide: services, onSlideUpdate, previousSlide, nextSlide } = useSlide(props.data ?? [], "serviceCard")

    useEffect(() => {
        onSlideUpdate()
    }, [props.data])

    useHoverGlow("#ourService")
    
    return (
        <div id="ourService" className="section">
            <Line width={1} height={160} containerClassName={"vLine1"} />
            <Title title={"OUR SERVICES"} icon={icon} />
            
            <div id="ourServiceCards">
                {
                    services?.map((service, index) => {
                        return (
                            <OurServiceCard key={index} data={service} />
                        )
                    })
                }
            </div>

            <Pagination id={"ourServicePageNav"} 
                prevSlide={previousSlide}
                nextSlide={nextSlide}
            />
        </div>
    )
}

export const OurServiceCard = (props: { data: IService }) => {
    const { data } = props
    
    let churchDate = {
        day: "",
        day_of_week: "",
        month: "",
        year: 0
    }

    if(data.church_date) {
        const date = new Date(data.year, data.month - 1, data.day)

        churchDate = {
            day: date.getDate().toString(),
            day_of_week: data.day_of_week,
            month: date.toLocaleString('en-US', { month: 'long' }).toUpperCase(),
            year: data.year
        }
    }

    return (
        <div className="card serviceCard">
            <div className="cardImage"
                style={{ backgroundImage: `url(${BaseImagePath + data.image_url})` }}
            />

            <div className="cardContent">
                <div className="date">
                    <div className="left">
                        {churchDate.day}
                    </div>
                    <div className="right">
                        <div className="month">
                            {churchDate.month}
                        </div>
                        <div className="year">
                            {churchDate.year}
                        </div>
                    </div>
                </div>

                <h2 className="serviceTitle">
                    {data.title}
                    <div className="line" />
                </h2>

                <div className="ministryContainer">
                    <div className="header">
                        <h3 className="ministryTitle">
                            {data.type}
                        </h3>
                        <div className="line" />
                    </div>
                    <p>
                        {data.description}
                    </p>
                    <Link to={"/ministries"} className="link">
                            // discover more
                    </Link>
                    <div className="serviceDetail">
                        <div className="detail">
                            <span className="title">ONLINE SERVICE</span><span className="description">
                                <a href={data.link_url}>&nbsp;| {data.link_text}</a>
                            </span>
                        </div>
                        <div className="detail">
                            <span className="title">ONSITE SERVICE</span><span className="description">
                                &nbsp;| {churchDate.day_of_week}
                            {data.church_time && data.church_time.map((hour, index) => {
                                return (
                                    <span key={index}>
                                        {" | "}
                                        <span dangerouslySetInnerHTML={{__html: formatHour(hour)}} />
                                    </span>
                                )
                            })}
                            </span>
                        </div>
                    </div>
                </div>

                <SpecialButton join_link={data.join_link} join_link_enable={data.join_link_enable} />
            </div>
        </div>
    )
}

const formatHour = (hour: number) => {
    if (hour === 0) {
        return '<b>12</b>am';
    }

    if (hour === 12) {
        return '<b>12</b>pm';
    }

    if (hour < 12) {
        return `<b>${hour}</b>am`;
    }

    return `<b>${hour - 12}</b>pm`;
}